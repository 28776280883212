<template>
    <div id="page-ip-manager-allocate" class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="heading">Allocation IP</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-search-box
                    v-model="word"
                    label="Enter CIDR subnet"

                    :loading="loading.details"
                    :disabled="loading.details"
                    
                    @search="search"
                />
            </div>
        </div>

        <div class="row" v-if="is_cidr_find">
            <div class="col-12">
                <div class="board">
                    <app-loader v-if="loading.details"></app-loader>

                    <div class="info details-info">
                        <table class="details-info">
                            <thead>
                                <tr v-for="row in details.rows.thead" :key="`details-${ row.key }`">
                                    <th>{{ row.title }}:</th>
                                    <td v-if="details.info[row.key]">{{ details.info[row.key] }}</td>
                                    <td v-else v-html="'&nbsp;'"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in details.rows.tbody" :key="`details-${ row.key }`">
                                    <th>{{ row.title }}:</th>
                                    <td v-if="details.info[row.key]">{{ details.info[row.key] }}</td>
                                    <td v-else v-html="'&nbsp;'"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info customer-info">
                        <app-loader v-if="loading.customer"></app-loader>

                        <table v-if="customer.info">
                            <thead>
                                <tr v-for="row in details.rows.thead" :key="`customer-${ row.key }`">
                                    <th v-html="'&nbsp;'"></th>
                                    <td v-html="'&nbsp;'"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in customer.rows.tbody" :key="`customer-${ row.key }`">
                                    <th>{{ row.title }}:</th>
                                    <td v-if="customer.info[row.key]">{{ customer.info[row.key] }}</td>
                                    <td v-else v-html="'&nbsp;'"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'

import appSearchBox from '@/components/app-search-box'

export default {
    components: {
        appLoader,

        appSearchBox,
    },

    data() {
        return {
            word: '',

            details: {
                info: null,

                rows: {
                    thead: [
                        { key: 'source',   title: 'Source'        },
                        { key: 'ip',       title: 'IP Address'    },
                    ],

                    tbody: [
                        { key: 'inetnum',  title: 'inetnum'       },
                        { key: 'netname',  title: 'netname'       },
                        { key: 'country',  title: 'country'       },
                        { key: 'org',      title: 'org'           },
                        { key: 'status',   title: 'status'        },
                        { key: 'created',  title: 'created'       },
                        { key: 'modified', title: 'last-modified' },
                        { key: 'source',   title: 'source'        },
                        { key: 'route',    title: 'route'         },
                        { key: 'origin',   title: 'origin'        },
                        { key: 'mnt_by',   title: 'mnt-by'        },
                    ],
                },
            },

            customer: {
                info: null,

                rows: {
                    tbody: [
                        { key: 'person',   title: 'person'        },
                        { key: 'address',  title: 'address'       },
                        { key: 'phone',    title: 'phone'         },
                        { key: 'created',  title: 'created'       },
                        { key: 'modified', title: 'last-modified' },
                    ],
                },
            },

            loading: {
                details: false,
                customer: false,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.cidr) {
                this.search(this.$route.params.cidr)
            }
        },

        search(word) {
            this.word = word

            this.loading.details = true

            setTimeout(() => {
                if (word == '123.45.67.89') {
                    this.details.info = {
                        source: 'whois.ripe.net',
                        ip: '123.45.67.89',
                        inetnum: '123.456.789.0 - 123.456.789.000',
                        netname: 'Lorem ipsum dolor',
                        country: 'NZ',
                        org: 'ORG-NZ011-RIPE',
                        status: 'ASSIGNED',
                        created: '17/08/2020, 05:39:15',
                        modified: '17/08/2020, 05:40:55',
                        source: 'RIPE',
                        route: '123.456.789.0/24',
                        origin: 'AA11111',
                        mnt_by: 'qwert-asd',
                    }

                    this.loading.customer = true

                    setTimeout(() => {
                        this.customer.info = {
                            person: 'Emmy Elsner',
                            address: '11-21 Fitzpatrick Street, Wellington 6037, New Zealand',
                            phone: '+64219191191',
                            created: '17/08/2020, 05:39:15',
                            modified: '17/08/2020, 05:40:55',
                        }

                        this.loading.customer = false
                    }, 250)
                } else {
                    this.details.info = null
                    this.customer.info = null
                }

                this.replaceRouteState()

                this.loading.details = false
            }, 500)
        },

        replaceRouteState() {
            window.history.replaceState({}, null, 
                window.location.origin + this.$router.resolve({
                    name: this.$route.name,
                    params: {
                        cidr: this.word,
                    }
                }).href
            )
        },
    },

    computed: {
        is_cidr_find() {
            return Boolean(this.details.info)
        },
    },
}
</script>

<style lang="scss">
#page-ip-manager-allocate {
    padding-bottom: 80px;

    h1 {
        margin-bottom: 48px;
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .board {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        padding: 8px 12px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-loader {
            border-radius: $border-radius-secondary;
        }

        table {
            text-align: left;
            border-collapse: separate; 
            border-spacing: 0 16px;
            margin: 0 12px;

            thead {
                font-size: 20px;
                line-height: 22px;

                th {
                    color: var(--color-table-head-secondary);
                }

                tr {
                    &:last-child {
                        th, td {
                            padding-bottom: 8px;
                        }
                    }
                }
            }

            th {
                width: 150px;
                padding: 0 15px 0 0;
            }

            td {
                padding: 0 0 0 15px;
            }
        }

        .info {
            min-width: 40%;
            min-height: 120px;
            position: relative;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-ip-manager-allocate {
        .board {
            flex-wrap: wrap;

            table {
                width: 100%;
            }

            .customer-info {
                table {
                    thead {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-ip-manager-allocate {
        h1 {
            margin-bottom: 32px;
        }

        .app-search-box {
            margin-bottom: 15px;
        }

        .board {
            padding: 0 #{ 15px * .5 };

            table {
                margin: 0 #{ 15px * .5 };

                th {
                    width: 100px;
                    padding: 0 #{ 15px * .5 } 0 0;
                }

                td {
                    padding: 0 0 0 #{ 15px * .5 };
                }
            }

            .info {
                width: 100%;
            }
        }
    }
}
</style>